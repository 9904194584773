import React, { useEffect } from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby";
import '../../styles/Article.css'
import { DefaultGtag } from '../../utils/misc'


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const ArticlePage = () => {

  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
  }, [])

  return (
    <Layout>
      <main style={mainStyles}>
        <div className="blogpage">
          <div className="blog">
            <h1 className="title">5 things to consider when creating a digital menu for your restaurant</h1>
            <p>When creating digital menus for your restaurant, there are several important factors to consider to ensure that they are effective and user-friendly. Here are a few things to look out for when creating digital menus:</p>
            <h2>Clear and easy-to-read design</h2>
            <p>Make sure the design is clear and easy to read. Digital menus should be easy to navigate and understand, so that customers can quickly find the information they are looking for. Use a clean and simple design, with clear headings and subheadings, and make sure the font is large enough to be easily read on a screen.</p>
            <h2>High-quality images and videos</h2>
            <p>Use high-quality images and videos. Digital menus are a great opportunity to showcase your dishes and make them more appealing to customers. Use high-quality photos and videos to show off your food and make it look as delicious as possible. This can help to increase customer interest and encourage them to try new items on the menu.</p>
            <h2>Detailed information about each dish</h2>
            <p>Provide additional information about each dish. Digital menus can be a great way to provide customers with more detailed information about the items on your menu. You can include ingredients, allergens, and nutritional information for each dish, which can be especially helpful for customers with dietary restrictions or preferences.</p>
            <h2>Easy to update and change</h2>
            <p>Make sure the menu is easy to update and change. One of the main advantages of digital menus is that they are easy to update and change. However, this can only be a benefit if the process of making changes is straightforward and user-friendly. Make sure that your digital menu system allows you to easily add, remove, or update items on the menu, so that you can quickly respond to any changes in your restaurant's offerings.</p>
            <h2>Testing the menu before launching</h2>
            <p>Test the menu before launching it. Before launching your digital menu, it is important to test it to make sure it works as intended. This can involve testing the menu on different devices, such as smartphones and tablets, to ensure that it is compatible and easy to use. You can also ask staff and customers to try the menu and provide feedback, so that you can make any necessary adjustments before going live.</p>
            <h2>Conclusion</h2>
            <p>By considering these factors when creating your digital menu, you can ensure that it is effective and user-friendly, and that it provides a positive experience for your customers. If are looking for a trusted partner for your menus, consider creating an account at <Link to="/solutions/digital-menu/" style={{ color: "#2cb052", fontWeight: "bold" }}>Take a Seat</Link>, all in self-service for you to get started right now. No demo meeting required, no technical skill required and awesome support.</p>
            <br />
            <br />
            <h2>About the author</h2>
            <p>This article if offered by Take a Seat, the digital partner of restaurants.</p>
            <p>We work to keep our articles short and to the point. If you would like more information, see menu examples, or need help setting up your QR code menus, feel free to contact our team at <a rel="nofollow" href="mailto:team@takeaseat.io" style={{ color: "blue" }}>team@takeaseat.io</a>. </p>
            <p style={{ paddingBottom: "3rem" }}>To create your restaurant's menu with us, visit our website at <a rel="author" href="https://takeaseat.io" style={{ color: "blue" }}>takeaseat.io</a>, it takes only 5 minutes from start to finish!</p>
          </div>
        </div>
      </main>
    </Layout >
  )
}

export default ArticlePage

export const Head = () => <>
  <DefaultGtag />
  <link rel="canonical" href="https://takeaseat.io/article/5-things-to-consider-when-creating-digital-menus/" />

  <title>5 things to consider when creating a digital menu for your restaurant</title>
  <meta name="description" content="What should you pay attention to when using digital menus? In this post we highlight 5 of the main reasons." />

  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:site_name" content="Blog of Take a Seat" />
  <meta property="og:title" content="5 things to consider when creating a digital menu for your restaurant" />
  <meta property="og:description" content="What should you pay attention to when using digital menus? In this post we highlight 5 of the main reasons." />
  <meta property="og:url" content="https://takeaseat.io/article/5-things-to-consider-when-creating-digital-menus/" />

  <meta name="twitter:card" content="summary" />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
  <meta name="twitter:title" content="5 things to consider when creating a digital menu for your restaurant" />
  <meta name="twitter:description" content="What should you pay attention to when using digital menus? In this post we highlight 5 of the main reasons." />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      "headline": "5 things to consider when creating a digital menu for your restaurant",
      "author": "Take a Seat",
      "description": "What should you pay attention to when using digital menus? In this post we highlight 5 of the main reasons.",
      "url": "https://takeaseat.io/article/5-things-to-consider-when-creating-digital-menus/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://takeaseat.io/article/5-things-to-consider-when-creating-digital-menus/"
      },
      "datePublished": "2022-05-30",
      "dateModified": "2022-09-30",
      "publisher": {
        "@type": "Organization",
        "name": "Take a Seat",
        "logo": {
          "@type": "ImageObject",
          "url": "https://takeaseat.io/media/icons/tas-230427.svg"
        }
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://takeaseat.io/static/media/images/things-to-consider.jpg"
      }
    })}
  </script>
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;